import {
  ActivityLogEntity,
  CareerServiceExternshipFile
} from '@api/models/careerServicesApi.models';
import {
  AttestationEntity,
  AttestationStatus
} from '@interfaces/attestation.interface';
import {
  Codebook,
  ExternshipProgramCodebook
} from '@api/models/codebook.models';
import { CandidateProfile } from '@api/models/candidateApi.models';
import { CompanyProfile } from '@api/models/companyApi.models';
import { CourseData } from './enrollmentApi.models';
import { EmployerProfile, KeycloakUserEntity } from '@api/models/employerApi.models';
import { FileType } from '@api/models/fileApi.models';
import { Location } from '@interfaces/location.interfaces';

export enum ExternshipPhase {
  UNPROPOSED = 'UNPROPOSED',
  PROPOSED = 'PROPOSED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED'
}

export enum ExternshipStatus {
  DRAFT = 0,
  PENDING = 1,
  PENDING_SOFT_VERIFICATION = 11,
  CS_REJECTED = 12,
  PENDING_SITE_AGREEMENT = 6,
  PENDING_EXT_MNG_AGREEMENT = 71,
  PENDING_EXT_STD_AGREEMENT = 72,
  PENDING_EXT_CS_AGREEMENT = 73,
  // TODO: does this need to be a status? It does not determine if the externship becomes active.
  PENDING_PROXY_AGREEMENT = 8,
  // TODO: Add pending start date status?
  ACTIVE = 2,
  DECLINED = 3,
  CANCELED = 32,
  ARCHIVED = 4,
  COMPLETED = 5,
  NO_PASS = 7
}

export enum AgreementType {
  SITE = 10,
  PROXY = 20,
  EXTERNSHIP = 30
}

export enum ExternshipActivityType {
  ASSIGNMENT = 'assignment',
  STATUS_UPDATE = 'status-update',
  TIMESHEET = 'timesheet',
  COMMENTS = 'comments',
  VERIFICATION = 'verification',
  EMAIL = 'email'
}

export enum ExternshipProgressType {
  SITE_VERIFIED = 'site-verified',
  SUPERVISOR_VERIFIED = 'supervisor-verified',
  EMAIL_VERIFIED = 'email-verified',
  JOB_DESC_VERIFIED = 'job-desc-verified',
  EMPLOYMENT_VERIFIED = 'employment-verified',
  EXTENSION_VERIFIED = 'employment-extension-verified' // NOT A CHECK BOX
}

export enum TimesheetStatus {
  CREATED = 0,
  DRAFT = 5,
  PENDING_SUPERVISOR_APPROVAL = 10,
  PENDING_INSTRUCTOR_APPROVAL = 15,
  ACCEPTED = 20,
  SENT_SUCCESSFULLY = 21,
  SENT_FAILED = 22,
  SENT_UNKNOWN = 29,
  REJECTED_BY_SUPERVISOR = 30,
  REJECTED_BY_INSTRUCTOR = 35,
  // Timesheets would need to but put in this status manually
  // as there is no automatic switch to this status
  // If in this status it means that we are unable to post the
  // time due to known issues which means we should not attempt
  // to repost to campus
  SENT_ACCEPTED_FAILURE = 40
}

// TIMESHEET HOURS DID NOT MAKE IT TO CVUE IF IT HAS ONE OF THESE STATUSES
export const FAILED_TIMESHEET_STATUSES = [
  TimesheetStatus.SENT_FAILED,
  TimesheetStatus.SENT_UNKNOWN,
  TimesheetStatus.SENT_ACCEPTED_FAILURE
];

export type AddExternshipApproverPayload =  {
  email: string,
} & Pick<ExternshipApproverEntity, 'firstName' | 'lastName' | 'jobTitle' | 'phone' | 'approverRole'>

export enum APPROVER_ERROR {
  INVALID_ROLE = 10, // 'Invalid role' - approver should be a recruiter or employer
  COMPANY_MISMATCH = 20 // 'Company Mismatch' - approver should work for the same company as site proposal
}

/**
 * @deprecated
 * Use ExternshipProposalApprover
 */
export interface ExternshipProposedManagerOrSupervisor {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  approverRole: 'manager' | 'supervisor' | 'mentor';
  approverHasCreatedAccount?: boolean;
  approverError?: APPROVER_ERROR;
}

export interface ChefInstructorEntity {
  campusInstructorId: number;
  email: string | null;
  id: number;
  name: string;
  userId: string | null;
}

export interface ExternshipProposedCompany {
  id: number;
  location: Location;
  phone: string;
  supportedPrograms: Codebook[];
  manager: ExternshipProposedManagerOrSupervisor;
  supervisors?: ExternshipProposedManagerOrSupervisor[];
}

export interface ExternshipSupervisor {
  id: number;
  externship: ExternshipEntity;
  employer: EmployerProfile;
  proxyAgreement: AgreementEntity | null;
  active?: boolean;
  removeDate?: Date | null;
}

export interface AgreementEntity {
  id: number;
  agreementType: AgreementType;
  file: FileType;
  candidateSignatory?: CandidateProfile;
  candidateSignDate?: Date;
  managerSignatory?: EmployerProfile;
  managerSignDate?: Date;
  supervisorSignatory?: EmployerProfile;
  supervisorSignDate?: Date;
  // User Id of career services signatory
  careerServiceSignatory?: string;
  careerServiceSignDate?: Date;
  externshipsNumber: number | null;
  minHours: number | null;
  payRate: number | null;
  compensationType: string | null;
  employmentStartDate?: string | null;
  revisedEmploymentStartDate?: string | null;
}

export interface ExternshipEntity {
  id: number;
  candidate: CandidateProfile;
  status: number;
  proposedCompany: ExternshipProposedCompany;
  jobTitle: string;
  jobDescription?: string | null;
  currentProgram: ExternshipProgramCodebook;
  course: CourseData;
  scheduledStartDate: string;
  employmentStartDate?: string;
  company: CompanyProfile;
  externshipApprovers: ExternshipApproverEntity[];
  approvers: ExternshipApproverEntity[];
  manager: EmployerProfile;
  managerActive: boolean;
  supervisors?: ExternshipSupervisor[];
  weeklyTimeSheets: Timesheet[];
  externshipAgreement?: AgreementEntity;
  attestation?: AttestationEntity | null;
  attestationStatus: AttestationStatus;
  chefInstructor?: ChefInstructorEntity;
  careerServiceFiles?: CareerServiceExternshipFile[];
  enrollmentId: number | null;
}

export interface UserInviteEntity {
  id?: number;
  email: string;
  inviteCode?: string;
  sentAt?: Date;
}

export enum ApproverRole {
  MANAGER = 'manager',
  SUPERVISOR = 'supervisor',
  MENTOR = 'mentor',
}

export interface ExternshipApproverEntity {
  id: number;
  user?: KeycloakUserEntity;
  firstName: string;
  lastName: string;
  approverRole: ApproverRole,
  canApproveTimesheets: boolean;
  proxyAgreement?: AgreementEntity;
  jobTitle: string;
  phone: string;
  invite?: UserInviteEntity;
}

export interface ExternshipTrackerPhaseAndStatus {
  phase: string;
  status: string;
}

export interface ExternshipWithPhaseAndStatus extends ExternshipEntity {
  externshipTrackerPhaseAndStatus: ExternshipTrackerPhaseAndStatus;
}
export interface StudentViewExternship extends ExternshipEntity {
  hasValidSiteAgreement: boolean;
  eligibleCoursesForRenewal: any[];
  courseScheduleChange: boolean;
}

export interface ExternshipWithActivityAndProgress extends ExternshipEntity {
  activity: ActivityLogEntity[];
  progress: ActivityLogEntity[];
}

export interface SiteAgreementData {
  agreementNeeded: boolean;
  signDate: string | null;
  signatory: string | null;
  agreementFullName: string | null;
  companyId: number;
  companyName: string;
  companyPhone: string;
  companyAddress: string;
  managerName: string; // employer.firstName + employer.lastName
  managerPhone: string;
  managerJobTitle: string;
}

export interface SiteAgreementPostReq {
  companyId: number;
  externshipsNumber: number;
  html: string;
}

export interface ExternshipAgreementPostReq {
  html: string;
  studentJobTitle: string;
  employmentStartDate: Date;
  compensationType: string;
  payRate: string | null;
}

export interface ReviseExternshipAgreementPostReq {
  html: string;
  revisedEmploymentStartDate: Date;
}

export interface TimesheetDay {
  id?: number;
  date: string;
  minutes: number;
}

export interface TimesheetPerformanceItem {
  id: number;
  ordinalNumber: number;
  question: string;
  active: boolean;
}

export interface TimesheetPerformanceRating {
  id?: number;
  rating: number;
  timesheetPerformanceItem: TimesheetPerformanceItem;
}

// Without relations
export interface ExternshipLight {
  id: number;
  status: ExternshipStatus;
  jobTitle: string;
  scheduledStartDate: string;
}

export enum LmsRespone {
  'OK' = 'OK',
  'FAIL' = 'FAIL',
  'UNKNOWN' = 'UNKNOWN'
}

export interface Timesheet {
  id: number;
  status: TimesheetStatus;
  dailyEntries: TimesheetDay[];
  externship: ExternshipLight;
  weekStartDate: string;
  weekEndDate: string;
  submitDate?: string | null;
  // Notes entered by candidate when submitting timesheet
  submitNotes?: string;
  acceptDate?: string | null;
  rejectDate?: string | null;
  // Comments entered by manager/supervisor approver when rejecting or approving timesheet
  comments?: string;
  // Chef Instructor that accepted the timesheet
  // Firstname, Lastname
  instructorAcceptedBy?: string;
  // Chef Instructor rejected the timesheet on this date
  instructorRejectDate?: string | null;
  // Comments entered by chef instructor approver when rejecting or approving timesheet
  instructorComments?: string;
  lmsSentDate?: string | null;
  lmsResponse?: LmsRespone | null;
  lmsErrorText?: string | null;
  lmsEventId?: string | null;
  performanceReview: TimesheetPerformanceRating[];
  acceptedBy?: EmployerProfile | null;
  // Manager/Supervisor that approved the timesheet as a string
  // This is necessary because emails are sometimes shared and we
  // need to know who signed the timesheet
  approverSignature?: string;
  rejectedBy?: EmployerProfile | null;
}

export interface TimesheetsWithExternship {
  externship: ExternshipWithoutTimesheets;
  timesheets: Timesheet[];
}

export interface SaveTimesheet {
  timesheetId?: number;
  externshipId: number;
  weekStartDate: string;
  weekEndDate: string;
  submitNotes?: string;
  dailyEntries: TimesheetDay[];
}

export interface ApproveTimesheetReq extends SaveTimesheet {
  comments?: string;
  instructorComments?: string;
  performanceReview: TimesheetPerformanceRating[];
}

export interface RejectTimesheetReq {
  comments: string;
}

export interface ExternshipWithoutTimesheets
  extends Omit<ExternshipEntity, 'weeklyTimeSheets' | 'attestationStatus'> { }

export interface CourseForExternshipAgreement {
  courseCode: string;
  courseName: string;
  workHours: number;
  startDate: Date;
  endDate: Date;
}

export interface ExternshipAgreementData {
  courseTitle: string;
  courseHours: number;
  startDate: string;
  companyName: string;
  companyPhone: string;
  companyAddress: string;
  managerName: string;
  managerPhone: string;
  managerJobTitle: string;
  managerEmail: string;
  studentName: string;
  studentPhone: string;
  studentAddress: string;
  studentEmail: string;
  studentJobTitle: string;
  employmentStartDate: string | null;
  revisedEmploymentStartDate?: string | null;
  compensationType: string | null;
  payRate?: string | null;
  candidateSignDate?: string | null;
  careerServiceSignDate?: string | null;
  managerSignDate?: string | null;
  careerServicesUserName?: string;
  studentProgram: string;
  coursesForAgreement: CourseForExternshipAgreement[];
}

// Progress & Activity
export interface ProgressItemPostReq {
  externshipId: number;
  userId: string;
  progressType: ExternshipProgressType; // ProgressType enum
  comment?: string;
  checked?: boolean;
}

export interface ExternshipDatesRes {
  dates: string[];
  externshipTrackerDatePreference: string;
}

export interface ActivateApproverReq {
  externshipId: number;
  supervisorId?: number | null;
}
