/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Fade, Icon, Link, Typography } from '@mui/material';
import { InviteInfo } from '@api/models/inviteApi.models';
import { Redirect, useHistory } from 'react-router-dom';
import BasePageOne from '@components/Layout/BasePageOne';
import CreateAccountTypeOption from '@pages/Public/CreateAccountPage/CreateAccountTypeOption';
import CreateCandidateAccountForm from '@pages/Public/CreateAccountPage/CreateCandidateAccountForm';
import CreateEmployerAccountForm from '@pages/Public/CreateAccountPage/CreateEmployerAccountForm';
import EWithCrownLogo from '@assets/png/e_with_crown_logo.png';
import { InviteApi } from '@api/Invite.api';
import { loginWithStorageRedirect } from '@common/helpers/login';
import { roles } from '@api/models/userApi.models';
import RootPageLayout from '@components/Layout/RootPageLayout';
import StudentComplianceModal from '@pages/Public/CreateAccountPage/StudentComplianceModal';
import { styled } from '@mui/system';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useKeycloak } from '@react-keycloak/web';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { useLocation } from 'react-router-dom';
import CreateAccountWithInviteForm from './CreateAccounWithInviteForm';

const Styled = {
  ConfirmationMessage: styled(Typography)({
    textAlign: 'center',
    padding: '64px 16px'
  }),
  AccountTypeSelectionWrapper: styled(Box)({
    margin: '0 auto',
    maxWidth: '480px',
    '& > *:not(:last-child)': { marginBottom: '24px' }
  }),
  Text: styled(Typography)(({ theme }) => ({
    color: theme.palette.GOLD_1.main
  })),
  Icon: styled(Icon)(({ theme }) => ({
    marginTop: '16px',
    color: theme.palette.GOLD_1.main
  })),
  Logo: styled('img')({
    width: '30px',
    height: 'auto'
  }),
  ErrorText: styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    margin: '32px 0px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  }))
};

const CreateAccountPage: React.FC = () => {
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const { keycloakUser, hasValidRole } = useKeycloakContext();

  if (hasValidRole) {
    return <Redirect to="/user/dashboard" />;
  }

  const featureFlags = useFeatureFlags();

  const search = useLocation().search;
  const typeQueryParam = new URLSearchParams(search).get('type');
  const inviteCodeQueryParam = new URLSearchParams(search).get('invite-code');

  const [accountType, setAccountType] = React.useState<roles>();
  const [emailConfirmationSent, setEmailConfirmationSent] =
    React.useState(false);
  const [inviteInfo, setInviteInfo] = React.useState<InviteInfo>();
  const [complianceModalOpen, setComplianceModalOpen] = React.useState(false);
  const [inviteCodeError, setInviteCodeError] = React.useState<string>();

  const handleOpenComplianceModal = (): void => {
    setComplianceModalOpen(true);
  };

  const handleCloseComplianceModal = (): void => {
    setComplianceModalOpen(false);
  };

  const login = React.useCallback(() => {
    loginWithStorageRedirect(keycloak.login);
  }, [loginWithStorageRedirect, keycloak.login]);

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (inviteCodeQueryParam) {
          const res = await InviteApi.getInviteInfo(inviteCodeQueryParam);
          setInviteInfo(res.data);
        }
      } catch (error: any) {
        console.error('Error for CreateAccountPage.getInviteInfo()', error);
        const inviteError = error?.response?.data?.message;
        setInviteCodeError(inviteError);
      }
    };
    fetchData();
  }, [inviteCodeQueryParam, accountType]);

  React.useEffect(() => {
    const isValidType = Object.values(roles).some((r) => r === typeQueryParam);

    if (typeQueryParam && accountType) {
      return;
    }

    if (typeQueryParam && !isValidType) {
      setAccountType(undefined);
      history.replace('/create-account');
    } else {
      setAccountType(typeQueryParam as roles);
    }

    if (!typeQueryParam) {
      setAccountType(undefined);
    }
  }, [typeQueryParam]);

  const onStudentSsoClick = (): void => {
    const url = keycloak.createLoginUrl({
      idpHint: process.env.REACT_APP_STUDENT_PORTAL_IDP_ALIAS,
      redirectUri: `${window.location.href}?type=candidate`
    });

    window.location.href = url;
  };

  const accountTypeOptions = [
    ...(featureFlags.STUDENT_PORTAL_SSO
      ? [
        {
          title: 'Join as an Escoffier Student',
          text: 'Manage externships and develop your career',
          image: <Styled.Logo src={EWithCrownLogo} alt="Escoffier-E-Crown" />,
          handleSelection: (): void => {
            handleOpenComplianceModal();
          },
          button: featureFlags.STUDENT_PORTAL_SSO ? (
            <Button
              fullWidth
              variant="contained"
              disabled={!!keycloakUser}
              color="BLACK"
              onClick={handleOpenComplianceModal}
              sx={{ marginTop: '16px', height: 'auto' }}
            >
              Escoffier Student SSO
            </Button>
          ) : undefined
        }
      ]
      : []),
    {
      title: 'Join as an Employer',
      text: 'Post jobs and recruit top talent for your organization',
      image: <Styled.Icon fontSize="large" className="ri-store-3-line" />,
      handleSelection: (): void => {
        setAccountType(roles.EMPLOYER);
        history.push(`/create-account?type=${roles.EMPLOYER}`);
      }
    },
    {
      title: 'Join as a Career Seeker',
      text: 'Search and apply to jobs posted by our partners',
      image: <Styled.Icon fontSize="large" className="ri-briefcase-5-line" />,
      handleSelection: (): void => {
        setAccountType(roles.CANDIDATE);
        history.push(`/create-account?type=${roles.CANDIDATE}`);
      }
    }
  ];

  if (emailConfirmationSent) {
    return (
      <RootPageLayout muiMaxWidth={false}>
        <Styled.ConfirmationMessage variant="EC_TYPE_2XL">
          Your account has been successfully created. Please sign in to access
          your account.
        </Styled.ConfirmationMessage>
      </RootPageLayout>
    );
  }

  return (
    <>
      <BasePageOne title="Create Account">
        <Box>
          {!keycloakUser &&
            accountType !== roles.PROPOSED_EXTERNSHIP_MANAGER ? (
            <Typography variant="EC_TYPE_BASE" align="center" mb="48px">
              Get started with a free account or{' '}
              <Link
                underline="hover"
                href="#"
                onClick={login}
                sx={{ color: '#005EA2' }}
              >
                sign in
              </Link>
              .
            </Typography>
          ) : (
            <Box mb="48px"></Box>
          )}
        </Box>
        {inviteCodeError && (
          <Styled.ErrorText variant="EC_TYPE_2XL">
            {inviteCodeError}
          </Styled.ErrorText>
        )}

        {inviteInfo && (
          <Fade
            in={!!inviteInfo}
            timeout={1000}
          >
            <Box>
              <CreateAccountWithInviteForm
                inviteInfo={inviteInfo}
                setEmailConfirmationSent={setEmailConfirmationSent}
              />
            </Box>
          </Fade>
        )}

        {!inviteInfo && !accountType && !inviteCodeError &&  (
          <Box>
            <Styled.Text variant="EC_TYPE_2XL" align="center" mb="56px">
              How would you like to use EConnect?
            </Styled.Text>
            <Styled.AccountTypeSelectionWrapper>
              {accountTypeOptions.map((opt) => (
                <CreateAccountTypeOption key={opt.title} {...opt} />
              ))}
            </Styled.AccountTypeSelectionWrapper>
          </Box>
        )}

        {!inviteInfo && accountType === roles.EMPLOYER && (
          <Fade in={accountType === roles.EMPLOYER} timeout={1000}>
            <Box>
              <CreateEmployerAccountForm
                setEmailConfirmationSent={setEmailConfirmationSent}
                user={keycloakUser}
              />
            </Box>
          </Fade>
        )}

        {!inviteInfo && accountType === roles.CANDIDATE && (
          <Fade in={accountType === roles.CANDIDATE} timeout={1000}>
            <Box>
              <CreateCandidateAccountForm
                setEmailConfirmationSent={setEmailConfirmationSent}
                user={keycloakUser}
              />
            </Box>
          </Fade>
        )}
      </BasePageOne>
      <StudentComplianceModal
        open={complianceModalOpen}
        handleClose={handleCloseComplianceModal}
        handleContinueClick={onStudentSsoClick}
      />
    </>
  );
};

export default CreateAccountPage;
