import { FeatureFlagConfig } from '@common/hooks/useFeatureFlags';
import { NavItem } from '@components/Layout/Header/Header.models';

const accountSettingsNavItem = { label: 'Account Settings', path: '/account' };
const notificationSettingsNavItem = { label: 'Notifications', path: '/notifications' };
const companySettingsNavItem = { label: 'Company Settings', path: '/company' };
const customizationSettingsNavItem = { label: 'Customizations', path: '/customizations' };
const locationsSettingsNavItem = { label: 'Locations', path: '/locations' };

export const candidateSettingsNav = [
  accountSettingsNavItem,
  // { label: 'Notifications', path: '/notifications' } -- Candidate Notifications has not been implemented yet
];

export const ecAdminViewingAsEmployerNav = (
  featureFlags: FeatureFlagConfig,
  customThemeAccess: boolean
): NavItem[] => [
  companySettingsNavItem,
  ...(featureFlags.GLRC_CUSTOMIZATION && customThemeAccess
    ? [customizationSettingsNavItem]
    : [])
];

export const employerSettingsNav = (
  featureFlags: FeatureFlagConfig,
  customThemeAccess: boolean
): NavItem[] => [
  accountSettingsNavItem,
  companySettingsNavItem,
  accountSettingsNavItem,
  ...(featureFlags.COMPANY_LOCATION
    ? [locationsSettingsNavItem]
    : []),
  ...(featureFlags.GLRC_CUSTOMIZATION && customThemeAccess
    ? [customizationSettingsNavItem]
    : [])
];

export const recruiterSettingsNav = [
  accountSettingsNavItem,
  notificationSettingsNavItem,
];

export const escoffierAdminSettingsNav = [
  accountSettingsNavItem,
  notificationSettingsNavItem,
];

export const partnerSettingsNav = [];

export const careerServicesSettingsNav = [
  accountSettingsNavItem
];

export const authorizedUserSettingsNav = [
  accountSettingsNavItem
];

export const timesheetApproverSettingsNav = [
  accountSettingsNavItem
];