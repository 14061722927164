/* eslint-disable @typescript-eslint/indent */
import { NavigationHeader } from '@components/Layout/Header/Header.models';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const commonLinks = {
  econnect: { label: 'Search Jobs', path: '/econnect' },
  contactUs: { label: 'Contact Us', path: '/contact-us' },
  accountSettings: {
    label: 'Account Settings',
    path: '/settings/account',
    remixicon: 'ri-settings-4-fill',
    hideInMobile: true
  },
  companyProfile: {
    label: 'Edit Company Profile',
    path: '/employer/profile-setup',
    remixicon: 'ri-edit-2-fill',
    hideInMobile: false
  }
};

export const unauthenticatedNav: NavigationHeader = {
  primaryLinks: new Set([
    { label: 'Career Seekers', path: '/candidate/offerings' },
    commonLinks.econnect,
    { label: 'Employers', path: '/employer/offerings' },
    commonLinks.contactUs
  ]),
  avatarLinks: new Set(),
};

export const pendingUserNav: NavigationHeader = {
  primaryLinks: new Set(),
  avatarLinks: new Set(),
};

export const activeEmployerNav = (
  isParentCompany: boolean
): NavigationHeader => {
  const nav = {
    primaryLinks: new Set([
      { label: 'Employer Dashboard', path: '/employer/dashboard' },
      { label: 'Create New Job Post', path: '/employer/create-job-post' },
      commonLinks.econnect,
      commonLinks.contactUs
    ]),
    avatarLinks: new Set([commonLinks.accountSettings, commonLinks.companyProfile])
  };

  if (isParentCompany) {
    nav.avatarLinks.add({
      label: 'Parent Company Dashboard',
      path: '/parent/dashboard',
      remixicon: 'ri-dashboard-fill',
      hideInMobile: false
    });
  }

  return nav;
};

export const inactiveEmployerNav: NavigationHeader = {
  primaryLinks: new Set([
    { label: 'Employer Dashboard', path: '/employer/dashboard' },
    commonLinks.econnect,
    commonLinks.contactUs
  ]),
  avatarLinks: new Set([commonLinks.accountSettings, commonLinks.companyProfile]),
};

export const candidateNav = (candidateId: number): NavigationHeader => {
  const featureFlags = useFeatureFlags();

  return {
    primaryLinks: new Set([
      { label: 'Candidate Dashboard', path: '/candidate/dashboard' },
      {
        label: 'Manage Profile',
        path: '/candidate/profile-setup',
        hideInMobile: true
      },
      commonLinks.econnect,
      commonLinks.contactUs
    ]),
    avatarLinks: new Set([
      {
        label: 'Manage Profile',
        path: '/candidate/profile-setup',
        remixicon: 'ri-edit-2-fill',
        mobileIcon: 'ri-account-circle-fill'
      },
      {
        label: 'View Profile',
        path: `/candidate/${candidateId}/profile`,
        remixicon: 'ri-file-user-line'
      },
      ...(featureFlags.CAREER_PATH
        ? [
          {
            label: 'View Career Journey',
            path: '/career-journey',
            remixicon: 'ri-briefcase-line'
          }
        ]
        : []),
      commonLinks.accountSettings
    ]),
  };
};

export const limboSsoNav = {
  primaryLinks: new Set([commonLinks.econnect, commonLinks.contactUs]),
  avatarLinks: new Set([commonLinks.accountSettings]),
};

export const partnerNav = (): NavigationHeader => {
  return {
    primaryLinks: new Set([
      // TODO: enable these links once implemented for partner role
      commonLinks.contactUs
    ]),
    avatarLinks: new Set(),
  };
};

export const escoffierAdminNav = (): NavigationHeader => {
  const featureFlags = useFeatureFlags();

  return {
    primaryLinks: new Set([
      { label: 'Admin Dashboard', path: '/admin/dashboard' },
      { label: 'Manage Companies', path: '/admin/manage-companies' },
      commonLinks.econnect,
      ...(featureFlags.CONTENT_MANAGEMENT
        ? [
          {
            label: 'Content Management',
            path: '/admin/content-management/hot-jobs',
            hideInMobile: true
          }
        ]
        : [])
    ]),
    avatarLinks: new Set([commonLinks.accountSettings])
  };
};

export const careerServicesNav = (isDev: boolean): NavigationHeader => {
  return {
    primaryLinks: new Set([
      {
        label: 'Career Services Dashboard',
        path: isDev ? '/career-services/dashboard' : '/externship-tracker'
      },
      { label: 'View Companies', path: '/career-services/companies' },
      { label: 'Externship Tracker', path: '/externship-tracker' },
      commonLinks.econnect
    ]),
    avatarLinks: new Set([commonLinks.accountSettings]),
  };
};

export const chefInstructorNav: NavigationHeader = {
  primaryLinks: new Set([
    { label: 'Chef Instructor Dashboard', path: '/chef-instructor/dashboard' },
    { label: 'Externship Tracker', path: '/externship-tracker' }
  ]),
  avatarLinks: new Set(),
};

export const timesheetApproverNav: NavigationHeader = {
  primaryLinks: new Set([
    { label: 'Externship Timesheets', path: '/approver/dashboard' },
  ]),
  avatarLinks: new Set([commonLinks.accountSettings]),
};

