/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import ExternshipCard from './ExternshipCard/ExternshipCard';
import ExternshipCardSkeleton from '@components/Skeletons/ExternshipCardSkeleton';
import { ExternshipStatus } from '@api/models/externshipApi.models';
import { GraduationTypes } from '@api/models/adminDashboardApi.models';
import { Link as RouterLink } from 'react-router-dom';
import StudentCourseCard from '@pages/Candidate/CandidateDashboard/WelcomeSection/ExternshipsSection/StudentCourseCard/StudentCourseCard';
import { styled } from '@mui/system';
import { useCandidateDashboardContext } from '@common/context/candidateDashboardContext';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';

const Styled = {
  Root: styled(Box)({
    margin: '36px 0',
    '& .proposal-link-text': {
      marginTop: '24px',
      display: 'block'
    }
  }),
  NoExternships: styled(Box)(({ theme }) => ({
    padding: '16px 32px',
    backgroundColor: theme.palette.WHITE.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.GRAY_2.main}`,
    borderRadius: '4px'
  }))
};

const NoExternships: React.FC<{ isStudent: boolean; hideBtn: boolean }> = ({
  isStudent,
  hideBtn
}) => (
  <Styled.NoExternships>
    <Typography variant="EC_TYPE_BASE">
      You don&apos;t have an externship set up{isStudent && ' yet'}.
    </Typography>
    {isStudent && !hideBtn && (
      <Link component={RouterLink} to="/externship/proposal" underline="none">
        <Button variant="outlined">Get Started</Button>
      </Link>
    )}
  </Styled.NoExternships>
);

const ProposalButton = ({
  coursesAssignable
}: {
  coursesAssignable: boolean;
}): React.ReactElement => {
  return (
    <>
      {coursesAssignable ? (
        <Link
          className="proposal-link-text"
          variant="EC_TYPE_SM"
          component={RouterLink}
          to="/externship/proposal"
          underline="none"
        >
          Propose another location
        </Link>
      ) : (
        <Typography variant="EC_TYPE_SM" className="proposal-link-text">
          To propose another externship, remove an existing draft or proposal.
        </Typography>
      )}
    </>
  );
};

const ExternshipsSection: React.FC = () => {
  const featureFlags = useFeatureFlags();
  // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove externships and ExternshipCard when flag is updated.
  const {
    externships,
    candidateEnrollments,
    candidateProfile,
    loaded,
    coursesAssignable // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove when flag is updated
  } = useCandidateDashboardContext();

  const isStudent =
    candidateProfile?.graduationType.value === GraduationTypes.EC_STUDENT;

  // Check for externships with a status that is NOT archived
  const hasNonArchivedExternships = React.useMemo(() => {
    if (
      featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
      !!candidateEnrollments.length
    ) {
      return !!candidateEnrollments?.some((enrollment) =>
        enrollment.courses?.some((course) =>
          course.externships?.some(
            (externship) => externship.status !== ExternshipStatus.ARCHIVED
          )
        )
      );
    }

    return externships?.some((e) => e.status !== ExternshipStatus.ARCHIVED);
  }, [featureFlags, candidateEnrollments, externships]);

  const ExternshipCards = (): JSX.Element => {
    if (!loaded) {
      return <ExternshipCardSkeleton />;
    }

    // TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove this and delete the NoExternships component
    if (
      !hasNonArchivedExternships &&
      loaded &&
      !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE
    ) {
      return (
        <NoExternships
          isStudent={isStudent}
          hideBtn={
            !coursesAssignable && !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE
          }
        />
      );
    }

    return (
      <BottomMarginContentWrapper bottomMargin="24px">
        {featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE ? (
          <>
            {candidateEnrollments?.map((enrollment) => (
              <React.Fragment key={enrollment.id}>
                {enrollment.courses.map((course) => (
                  <StudentCourseCard
                    key={course.Id}
                    course={course}
                    enrollmentId={enrollment.id}
                    // expectedStartDate={enrollment.expectedStartDate}
                  />
                ))}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {/* TODO: this code is not used, need to figure out what to do with it */}
            {externships
              ?.filter((e) => e.status !== ExternshipStatus.ARCHIVED)
              .map((e) => {
                return <ExternshipCard key={e.id} externship={e} />;
              })}
          </>
        )}
      </BottomMarginContentWrapper>
    );
  };

  return (
    <Styled.Root>
      <Typography variant="EC_TYPE_LG" mb="24px">
        Your Externships
      </Typography>
      <ExternshipCards />
      {hasNonArchivedExternships &&
        !featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE && (
          <ProposalButton coursesAssignable={coursesAssignable} />
        )}
    </Styled.Root>
  );
};

export default ExternshipsSection;
