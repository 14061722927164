import * as React from 'react';
import {
  AgreementEntity,
  ApproverRole,
  ExternshipApproverEntity,
  ExternshipEntity,
  ExternshipStatus,
  StudentViewExternship,
} from '@api/models/externshipApi.models';
import {
  Box,
  Button,
  Icon,
  Theme,
  Typography
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ApproverActivation from './ApproverActivation';
import { ExternshipApi } from '@api/Externships.api';
import { ExternshipProposedManagerOrSupervisor } from '@api/models/externshipApi.models';
import RemoveApproverModal from '@pages/CareerServices/StudentPage/StudentSiteInfo/RemoveApproverModal';
import ResendModals from './ResendModals';
import { styled } from '@mui/system';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useStudentViewSiteInfoContext } from '@common/context/studentViewSiteInfoContext';
import { useToast } from '@components/Toast';
import ExternshipProposalApproverFieldArray from '@components/Forms/ExternshipProposalApproverFieldArray';
import { SiteInfoCardFormValues } from './StudentSiteInfo';
import { ExternshipProposalApprover } from '@api/models/externshipProposalApi.models';
import { sortExternshipProposalApprovers } from '@common/helpers/externshipHelpers/sortExternshipProposalApprovers';

const Styled = {
  Root: styled(Box)({
    '& > *:not(:first-of-type)': {
      marginTop: '24px'
    }
  }),
  PaddingBox: styled(Box)({
    '& > *:not(:last-child)': {
      paddingBottom: '8px'
    },
    display: 'flex',
    justifyContent: 'space-between'
  }),
  EditExternshipApproverBox: styled(Box)(({ theme }) => ({
    display: 'flex',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.OUTLINE.main}`,
      paddingBottom: '12px'
    },
    '& .remove-supervisor-btn': {
      color: theme.palette.primary.main,
      height: '13px',
      width: '13px',
      marginLeft: '8px'
    }
  })),
  WarningIcon: styled('i')({
    marginRight: '4px'
  }),
  MissingSignatureText: styled('span')(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.error.main
  })),
  ExistingUserText: styled('span')(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.GREEN_1.main
  })),
  ApproverTitle: styled(Typography)({
    textTransform: 'uppercase',
    marginBottom: '8px'
  }),
  AddBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: '12px',
    fontSize: '15px',
  })),
  TextBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_2XS,
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
      marginRight: 0
    },
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    minWidth: 0
  })),
  BtnIcon: styled(Icon)({
    fontSize: '15px !important'
  }),
  VerticalLine: styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: '1px',
    height: '10px'
  })),
  ApproverActionsWrapper: styled(Box)({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginTop: '8px'
  }),
  AddApproverWrapper: styled(Box)(({ theme }) => ({
    paddingTop: '10px',
    marginTop: '20px',
    borderTop: `1px solid ${theme.palette.GRAY_5.main}`,
    width: '100%'
  })),
  AddApproverBtn: styled(Button)(({ theme }) => ({
    ...(theme as Theme).typography.EC_TYPE_SM,
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'transparent'
    },
    marginTop: '5px',
    color: theme.palette.primary.main
  })),
  AddSupervisorIcon: styled(Icon)({
    marginLeft: '2px',
    marginRight: '-6px'
  })
};

const statusesEligibleToRemoveManager = [
  ExternshipStatus.PENDING,
  ExternshipStatus.PENDING_EXT_MNG_AGREEMENT,
  ExternshipStatus.PENDING_EXT_STD_AGREEMENT,
  ExternshipStatus.PENDING_EXT_CS_AGREEMENT,
  ExternshipStatus.PENDING_PROXY_AGREEMENT,
  ExternshipStatus.ACTIVE
];

/**
 * @deprecated
 * User ExternshipApproverEntity
 */
export interface SiteCardApproverDetails
  extends ExternshipProposedManagerOrSupervisor {
  proxyAgreement?: AgreementEntity | null;
  active?: boolean;
  approverHasCreatedAccount?: boolean;
}

interface ExternshipApproverSectionProps {
  index?: number;
  showResendBtn?: boolean;
  externship: ExternshipEntity;
  approver: ExternshipApproverEntity
}

interface ExternshipApproversDetailsProps {
  externship: ExternshipEntity;
}

const defaultApproverFormFields: ExternshipProposalApprover = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  jobTitle: '',
  approverRole: ApproverRole.SUPERVISOR,
};

const fieldGridSizes = {
  firstName: { xs: 6 },
  lastName: { xs: 6 },
  email: { xs: 12 },
  phone: { xs: 12 },
  jobTitle: { xs: 12 }
};

const getEmail = (approver: ExternshipApproverEntity): string | undefined => {
  return approver.user?.email || approver.invite?.email || undefined;
};

const ExternshipApproverSection = ({
  index,
  showResendBtn = false,
  externship,
  approver
}: ExternshipApproverSectionProps): React.ReactElement => {
  const { setExternships } = useStudentViewContext();

  const { openToast } = useToast();

  const [showRemoveSupervisorModal, setShowRemoveSupervisorModal] =
    React.useState<boolean>(false);
  const [resendError, setResendError] = React.useState<string>();
  const [resendModal, setResendModal] = React.useState<boolean>(false);

  const isManager = approver.approverRole === ApproverRole.MANAGER;

  const TIMESHEET_ACCES_TOGGLE_STATUSES = [
    ExternshipStatus.ACTIVE,
    ExternshipStatus.NO_PASS,
    ExternshipStatus.COMPLETED
  ];

  const showActivationButton =
    approver.user &&
    TIMESHEET_ACCES_TOGGLE_STATUSES.includes(externship.status) &&
    approver.canApproveTimesheets &&
    approver.proxyAgreement;

  const linkToDashboardButton = !!approver.user;

  const handleRemoveApprover = async (approver: ExternshipApproverEntity): Promise<void> => {
    try {
      const res = await ExternshipApi.removeExternshipApprover(externship.id, approver.id);
      openToast('Approver was removed successfully.');
      setShowRemoveSupervisorModal(false);

      const mapUpdatedExternship = (
        e: StudentViewExternship
      ): StudentViewExternship => {
        if (e.id === res.id) {
          return {
            ...e,
            status: res.status,
            approvers: res.approvers,
          };
        }
        return { ...e };
      };

      setExternships((prev) => prev.map(mapUpdatedExternship));

    } catch (error: any) {
      console.error(
        `Error for ApproverDetails -> ExternshipApi.removeSupervisor: ${error}`
      );
      const errorMsg = error?.response?.data?.message;
      setResendError(errorMsg);
    }
  };

  const DISABLED_TIMESHEET_ACCESS_STATUSES = [
    ExternshipStatus.NO_PASS,
    ExternshipStatus.COMPLETED
  ];

  const getSectionTitleByApproverRole = (externship: ExternshipEntity, approver: ExternshipApproverEntity): string => {
    const supervisorsCount = externship.approvers.filter((approvers) => { return approvers.approverRole === ApproverRole.SUPERVISOR; }).length;
    const mentorsCount = externship.approvers.filter((approvers) => { return approvers.approverRole === ApproverRole.MENTOR; }).length;

    switch (approver.approverRole) {
      case ApproverRole.MANAGER:
        return 'Manager';

      case ApproverRole.SUPERVISOR:
        return 'Supervisor'.concat(supervisorsCount > 1 ? 's' : '');

      case ApproverRole.MENTOR:
        return 'Mentor'.concat(mentorsCount > 1 ? 's' : '');

      default:
        return '';
    }
  };

  return (
    <Styled.PaddingBox>
      <Box sx={{ width: '100%' }}>
        {(index === undefined || index === 0) && (
          <Styled.ApproverTitle variant="EC_TYPE_2XS">
            {getSectionTitleByApproverRole(externship, approver)}
          </Styled.ApproverTitle>
        )}
        <Typography variant="EC_TYPE_SM" fontWeight={700}>
          {approver.firstName} {approver.lastName}
        </Typography>
        <Typography variant="EC_TYPE_2XS">{getEmail(approver)}</Typography>
        <Typography variant="EC_TYPE_2XS">{approver.phone}</Typography>
        <Typography variant="EC_TYPE_2XS">{approver.jobTitle}</Typography>
        {approver.user?.id && (
          <Box>
            <Styled.ExistingUserText className="missing-signature-span">
              <Styled.WarningIcon className="ri-user-3-line" />
              Existing User
            </Styled.ExistingUserText>
          </Box>
        )}
        {isManager && (
          <Styled.MissingSignatureText className="missing-signature-span">
            <Styled.WarningIcon className="ri-error-warning-line" />
            Missing signature (Externship Agreement)
          </Styled.MissingSignatureText>
        )}
        {!isManager && !approver.proxyAgreement && (
          <Styled.MissingSignatureText className="missing-signature-span">
            <Styled.WarningIcon className="ri-error-warning-line" />
            Missing signature (Proxy Agreement)
          </Styled.MissingSignatureText>
        )}

        <Styled.ApproverActionsWrapper>
          {showResendBtn && (
            <>
              <Styled.TextBtn
                variant="text"
                className="disable-while-loading"
                endIcon={
                  <Styled.BtnIcon
                    className={`${linkToDashboardButton ? 'ri-links-line' : 'ri-mail-send-line'}`}
                  />
                }
                onClick={(): void => setResendModal(true)}
              >
                {linkToDashboardButton ? 'Link to dashboard' : 'Resend'}{' '}
              </Styled.TextBtn>
              <Styled.VerticalLine />
            </>
          )}
          {statusesEligibleToRemoveManager.includes(externship.status) && (
            <Styled.TextBtn
              variant="text"
              className="disable-while-loading"
              endIcon={<Styled.BtnIcon className="ri-delete-bin-6-line" />}
              onClick={(): void => setShowRemoveSupervisorModal(true)}
            >
              Remove
            </Styled.TextBtn>
          )}
        </Styled.ApproverActionsWrapper>
        {resendError && (
          <Box>
            <Styled.MissingSignatureText>
              <Styled.WarningIcon className="ri-error-warning-line" />
              {resendError}
            </Styled.MissingSignatureText>
          </Box>
        )}
      </Box>
      {showActivationButton && (
        <ApproverActivation
          disabled={DISABLED_TIMESHEET_ACCESS_STATUSES.includes(
            externship.status
          )}
          externshipId={externship.id}
          approver={approver}
        />
      )}
      <RemoveApproverModal
        open={showRemoveSupervisorModal}
        approver={approver}
        handleClose={(): void => setShowRemoveSupervisorModal(false)}
        handleRemoveApprover={handleRemoveApprover}
      />
      {resendModal && (
        <ResendModals
          externshipId={externship.id}
          externshipStatus={externship.status}
          approver={approver}
          open={resendModal}
          closeModal={(): void => {
            setResendModal(false);
          }}
        />
      )}
    </Styled.PaddingBox>
  );
};

const ExternshipApproversDetails: React.FC<ExternshipApproversDetailsProps> = ({
  externship
}) => {
  const [resendModal, setResendModal] = React.useState<boolean>(false);

  const { getIsEditing } = useStudentViewSiteInfoContext();
  const isEditing = getIsEditing('Approvers');
  const { control, reset, getValues } = useFormContext<SiteInfoCardFormValues>();
  const { fields, append, remove } = useFieldArray({
    name: 'approvers',
    control
  });

  const manager = externship.approvers?.find(link => link.approverRole === ApproverRole.MANAGER);
  const supervisors = externship.approvers?.filter(link => link.approverRole === ApproverRole.SUPERVISOR).sort((a, b) => a.id - b.id);
  const mentors = externship.approvers?.filter(link => link.approverRole === ApproverRole.MENTOR);

  React.useEffect(() => {
    if (isEditing) {
      reset({
        ...getValues(),
        approvers: sortExternshipProposalApprovers(externship.approvers),
      });
    }
  }, [isEditing, externship]);

  const handleAddApprover = (): void => {
    append(defaultApproverFormFields);
  };

  const handleRemoveSupervisor = (idx: number): void => {
    remove(idx);
  };

  const invalidResendStatuses = [
    ExternshipStatus.DRAFT,
    ExternshipStatus.PENDING_SOFT_VERIFICATION,
    ExternshipStatus.CS_REJECTED,
    ExternshipStatus.DECLINED,
    ExternshipStatus.CANCELED,
    ExternshipStatus.ARCHIVED,
    ExternshipStatus.COMPLETED,
    ExternshipStatus.NO_PASS
  ];

  const showResendBtn = !invalidResendStatuses.includes(externship.status);

  const getApproverTitleByApproverRole = (idx: number, approverRole: ApproverRole): string => {
    const supervisorsCount = fields.filter((approvers) => { return approvers.approverRole != ApproverRole.MANAGER; }).length;

    switch (approverRole) {
      case ApproverRole.MANAGER:
        return 'Manager';

      default:
        return 'Supervisor'.concat(supervisorsCount > 1 ? ` #${idx}` : '');
    }
  };

  return (
    <Styled.Root>
      {!isEditing ? (
        <>
          {manager && (
            <Box>
              <ExternshipApproverSection
                approver={manager}
                externship={externship}
                showResendBtn={true}
              />
            </Box>
          )}
          {supervisors.map((supervisor, idx) => (
            <React.Fragment key={supervisor.id}>
              <ExternshipApproverSection
                externship={externship}
                approver={supervisor}
                index={idx}
                showResendBtn={showResendBtn}
              />
            </React.Fragment>
          ))}
          {mentors.map((supervisor, idx) => (
            <React.Fragment key={supervisor.id}>
              <ExternshipApproverSection
                externship={externship}
                approver={supervisor}
                index={idx}
                showResendBtn={showResendBtn}
              />
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {
            fields.map((field, idx) => (
              <>
                <Styled.ApproverTitle variant="EC_TYPE_2XS">
                  {getApproverTitleByApproverRole(idx, field.approverRole)}
                </Styled.ApproverTitle>
                <Styled.EditExternshipApproverBox key={idx}>
                  <ExternshipProposalApproverFieldArray
                    fieldArrayIndex={idx}
                    approverRole={field.approverRole}
                    disableLabels
                    disableAddExtraApproversSelect
                    showPlaceholders
                    containerSpacing={1}
                    fieldGridProps={fieldGridSizes}
                    handleRemoveFieldArrayItem={handleRemoveSupervisor}
                  />
                </Styled.EditExternshipApproverBox>
              </>
            ))
          }
          <Box>
            <Styled.AddApproverWrapper>
              <Styled.AddApproverBtn
                variant="text"
                startIcon={
                  <Styled.AddSupervisorIcon
                    style={{ fontSize: 19 }}
                    className="ri-user-add-line"
                  />
                }
                onClick={handleAddApprover}
              >
                Add Approver
              </Styled.AddApproverBtn>
            </Styled.AddApproverWrapper>
          </Box>
        </>
      )}
      {resendModal && manager && (
        <ResendModals
          approver={manager}
          externshipStatus={externship.status}
          externshipId={externship.id}
          open={resendModal}
          closeModal={(): void => {
            setResendModal(false);
          }}
        />
      )}
    </Styled.Root>
  );
};

export default ExternshipApproversDetails;
