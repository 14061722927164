import * as React from 'react';
import {
  AddExternshipApproverPayload,
  ApproverRole,
  ExternshipWithoutTimesheets,
  StudentViewExternship
} from '@api/models/externshipApi.models';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { Controller, FormProvider, useForm, ValidateResult } from 'react-hook-form';
import BasicModal from '@components/BasicModal';
import { emailPattern } from '@common/regex/email';
import { EmployerProfile } from '@api/models/employerApi.models';
import { ExternshipApi } from '@api/Externships.api';
import FormFieldErrorText from '@components/Forms/FormFieldErrorText';
import ReactHookFormsAutoCompleteSingle from '@components/Forms/ReactHookFormsAutoCompleteSingle';
import ReactHookFormsInput from '@components/Forms/ReactHookFormsInput';
import ReactHookFormsInputPhoneMask from '@components/Forms/ReactHookFormsInputPhoneMask';
import { useFetchEmployers } from '@common/fetches/useFetchEmployers';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { useToast } from '@components/Toast';

const getEmployers = (externship: ExternshipWithoutTimesheets, existingSupervisorsEmails?: string[]): EmployerProfile[] => {
  if (externship.company) {
    const { employers: companyEmployers } = useFetchEmployers(
      externship.company.id
    );

    // Removing any supervisor if already assigned to the externship
    return companyEmployers?.filter((companyEmployer: EmployerProfile) => {
      return !existingSupervisorsEmails?.includes(companyEmployer.email.toLowerCase()) &&
        externship.manager?.email.toLowerCase() !== companyEmployer.email.toLowerCase();
    }) || [];
  }

  return [];
};
interface Props {
  open: boolean;
  closeModal: () => void;
  externship: ExternshipWithoutTimesheets;
}

const AddApprover: React.FC<Props> = ({ open, externship, closeModal }) => {
  const { setExternships } = useStudentViewContext();

  const defaultValues: AddExternshipApproverPayload = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    jobTitle: '',
    approverRole: ApproverRole.SUPERVISOR,
  };

  const methods = useForm<AddExternshipApproverPayload>({
    defaultValues, mode: 'onChange'
  });

  const {
    watch,
    setValue,
    clearErrors,
  } = methods;

  const [disableActions, setDisableActions] = React.useState(false);
  const [errorMsg, setErrMsg] = React.useState('');
  const [existingEmployerSelected, setExistingEmployerSelected] = React.useState<EmployerProfile | null>();

  const emailInput = watch('email');
  const { openToast } = useToast();

  const manager = externship.approvers?.find(link => link.approverRole === ApproverRole.MANAGER);

  const existingApproversEmails = externship?.approvers
    .map((approver) => {
      return approver.user?.email || approver.invite?.email || '';
    });

  // Companies that are brand new to EC will not have a company_entity during PENDING status
  // since we are waiting for the manager to create their account/company
  const employers: EmployerProfile[] = getEmployers(externship, existingApproversEmails);

  // Employers list for the autocomplete dropdown
  const employersOptions = React.useMemo(() => {
    if (!employers) return []; // Return an empty array if employers is undefined
    return employers.map((employer) => ({
      label: `${employer.firstName} ${employer.lastName} - ${employer.email}`,
      value: employer.email
    }));
  }, [employers]);

  // Setting form fields if approver is existing employer
  React.useEffect(() => {
    const employerFound = employers.find((employer: EmployerProfile) => {
      return employer.email.toLowerCase() === emailInput;
    });

    if (employerFound) {
      clearErrors();
      setExistingEmployerSelected(employerFound);

    } else {
      setExistingEmployerSelected(null);
    }

    setValue('firstName', employerFound?.firstName || '');
    setValue('lastName', employerFound?.lastName || '');
    setValue('jobTitle', employerFound?.jobTitle || '');
    setValue('phone', employerFound?.phone || '');

  }, [emailInput, existingEmployerSelected, setExistingEmployerSelected]);

  const clearModal = (): void => {
    setErrMsg('');
    closeModal();
  };

  const onValid = async (data: AddExternshipApproverPayload): Promise<void> => {
    setDisableActions(true);
    setErrMsg('');

    try {
      const res = await ExternshipApi.addExternshipApprover(externship.id.toString(), data);

      const mapUpdatedExternship = (
        e: StudentViewExternship
      ): StudentViewExternship => {
        if (e.id === res.id) {
          return {
            ...e,
            status: res.status,
            approvers: res.approvers,
          };
        }
        return { ...e };
      };

      setExternships((prev) => prev.map(mapUpdatedExternship));
      openToast('Approver added successfully.');
      closeModal();

    } catch (error: any) {
      console.error('Error: AddApprover -> ExternshipApi.addExternshipApprover()');
      if (error?.response?.data?.message) {
        setErrMsg(error?.response?.data?.message);
      }
    } finally {
      setDisableActions(false);
      setExistingEmployerSelected(null);
    }
  };

  return (
    <BasicModal
      open={open}
      handleClose={clearModal}
      title="Add an Approver"
      primaryAction={{
        label: 'Save & Send Invite',
        action: methods.handleSubmit(onValid),
        disabled: disableActions
      }}
      secondaryAction={{
        label: 'Cancel',
        action: clearModal,
        disabled: disableActions
      }}
      maxWidth="sm"
    >
      <FormProvider {...methods}>
        <Typography variant="EC_TYPE_BASE" mb={4}>
          Add an existing user or enter an email to send an invite to join
          Escoffier.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReactHookFormsAutoCompleteSingle
              labelVariant="EC_TYPE_SM"
              name="email"
              openOnFocus={false}
              disablePortal
              freeSolo
              selectOnFocus
              blurOnSelect
              options={employersOptions}
              placeholder="Search Approver or type a email"
              rules={{
                validate: (value: string): ValidateResult => {
                  // Equal to `required`
                  if (!value) return 'Email is required';

                  // Equal to `pattern`
                  if (!emailPattern.test(value)) return 'Please enter a valid email';

                  // Custom validator
                  externship.approvers.find(approver => {
                    if (
                      approver.user?.email === value.toLowerCase() ||
                      approver.invite?.email === value.toLowerCase()
                    ) {
                      return `Email is already assigned to the externship's ${approver.approverRole}`;
                    }
                  });

                  return true;
                },
              }}
            />
          </Grid>
          <React.Fragment>
            <Grid item xs='auto' alignContent={'center'}>
              <FormLabel id="add-approver-is-manager-radio-buttons-group">Approver type</FormLabel>
            </Grid>
            <Grid item xs='auto' textAlign={'left'}>
              <Controller
                name='approverRole'
                control={methods.control}
                render={({ field }): JSX.Element => {
                  return (
                    <RadioGroup
                      {...field}
                      row
                      aria-labelledby="add-approver-is-manager-radio-buttons-group"
                    >
                      <FormControlLabel
                        label="Manager"
                        value={ApproverRole.MANAGER}
                        disabled={!!manager}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        label="Supervisor"
                        value={ApproverRole.SUPERVISOR}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        label="Mentor"
                        value={ApproverRole.MENTOR}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </Grid>
          </React.Fragment>
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-first-name-input"
                placeholder="First Name"
                name="firstName"
                rules={{
                  required: 'First Name is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-last-name-input"
                placeholder="Last Name"
                name="lastName"
                rules={{
                  required: 'Last Name is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInputPhoneMask
                name="phone"
                disabled={!!existingEmployerSelected}
                placeholder="Phone Number"
                dataTestId="add-approver-personal-phone-number-input"
                rules={{
                  required: 'Phone number is required'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ReactHookFormsInput
                disabled={!!existingEmployerSelected}
                dataTestId="add-approver-job-title-input"
                placeholder="Job Title"
                name="jobTitle"
                rules={{
                  required: 'Job title is required'
                }}
              />
            </Grid>
          </React.Fragment>
          {errorMsg && (
            <FormFieldErrorText
              message={errorMsg}
              disableAbsolutePosition
              name={`add-approver-${externship.id}`}
            />
          )}
        </Grid>
      </FormProvider>
    </BasicModal>
  );
};

export default AddApprover;
