import {
  ICompanySettings,
  Seat,
  SentInvite,
  SetCompanySettings,
  SetLoginSettings,
  UserLoginSettings
} from '@api/models/settingsApi.models';
import { CandidateApi } from '@api/Candidate.api';
import { CompanyApi } from '@api/Company.api';
import { EmployerProfile } from '@api/models/employerApi.models';
import { InviteApi } from '@api/Invite.api';
import { JoinRequest } from '@api/models/companyApi.models';
import { useState } from 'react';

interface FetchUserSettings {
  loginSettings?: UserLoginSettings;
  companySettings: ICompanySettings;
  sentInvites: SentInvite[];
  joinRequests: JoinRequest[];
  loading: boolean;
  fetchCandidateSettings: (userId: string) => Promise<void>;
  fetchCompanyLoginSettings: (userId: string) => Promise<void>;
  fetchCompanySettings: (
    companyId: number,
    companyProfileName?: string | null
  ) => Promise<void>;
  fetchSentInvites: (companyId: number) => Promise<void>;
  fetchJoinRequests: (companyId: number) => Promise<void>;
  fetchCompanySettingsRequestsAndInvites: (
    companyId: number,
    companyProfileName?: string | null
  ) => Promise<void>;
  setLoginSettings: SetLoginSettings;
  setCompanySettings: SetCompanySettings;
  setJoinRequests: React.Dispatch<React.SetStateAction<JoinRequest[]>>;
}

export const convertEmployersToSeats = (
  employers: EmployerProfile[]
): Seat[] => {
  const seats = employers.map((item) => {
    const nameArray = [item.firstName, item.lastName];
    return {
      employerId: item.id,
      email: item.email,
      name: nameArray.join(' '),
      role: item.roles,
      isSelf: item.owner,
      e_source_group: item.e_source_group
    } as Seat;
  });
  return seats;
};

export const useFetchSettings = (): FetchUserSettings => {
  const [loginSettings, setLoginSettings] = useState<UserLoginSettings>();
  const [companySettings, setCompanySettings] = useState<ICompanySettings>({
    companyName: '',
    seats: []
  });
  const [sentInvites, setSentInvites] = useState<SentInvite[]>([]);
  const [joinRequests, setJoinRequests] = useState<JoinRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCandidateSettings = async (userId: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await CandidateApi.getByKeycloakId(userId);
      setLoginSettings((prev) => ({
        ...prev,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phone: res.data.phone,
        jobTitle: ''
      }));
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CandidateApi.getByKeycloakId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyLoginSettings = async (userId: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await CompanyApi.getEmployerByKeycloakId(userId);
      setLoginSettings((prev) => ({
        ...prev,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phone: res.data.phone,
        jobTitle: res.data.jobTitle
      }));
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CompanyApi.getByKeycloakId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanySettings = async (
    companyId: number,
    companyProfileName?: string | null
  ): Promise<void> => {
    try {
      setLoading(true);

      const res = await CompanyApi.getEmployersByCompanyId(companyId);

      const seats = convertEmployersToSeats(res.data);

      setCompanySettings({
        companyName: companyProfileName ?? '',
        seats
      });
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CompanyApi.getEmployersByCompanyId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSentInvites = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);

      const res = await InviteApi.getSentInvitesByCompanyId(companyId);

      setSentInvites(res.data);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> InviteApi.getSentInvitesByCompanyId()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchJoinRequests = async (companyId: number): Promise<void> => {
    try {
      setLoading(true);

      const res = await CompanyApi.getPendingRequests(companyId);

      setJoinRequests(res);
    } catch (error: any) {
      console.error(
        'Error for useFetchUserSettings -> CompanyApi.getPendingRequests()',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanySettingsRequestsAndInvites = async (
    companyId: number,
    companyProfileName?: string | null
  ): Promise<void> => {
    Promise.all([
      fetchCompanySettings(companyId, companyProfileName),
      fetchSentInvites(companyId),
      fetchJoinRequests(companyId)
    ]);
  };

  return {
    loginSettings,
    companySettings,
    sentInvites,
    joinRequests,
    loading,
    fetchCandidateSettings,
    fetchCompanyLoginSettings,
    fetchCompanySettings,
    fetchSentInvites,
    fetchJoinRequests,
    fetchCompanySettingsRequestsAndInvites,
    setLoginSettings,
    setCompanySettings,
    setJoinRequests
  };
};
