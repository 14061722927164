import { CompanyProfile } from '@api/models/companyApi.models';
import { ESourceGroupEntity } from '@interfaces/glrc.interfaces';
import { UserRole } from '@api/models/userApi.models';
import { AgreementEntity, ApproverRole, ExternshipEntity } from './externshipApi.models';

export enum EmployerRole {
  COMPANY_ADMIN = 'company_admin',
  EMPLOYEE = 'employee',
  NON_EMPLOYEE = 'non_employee',
}

export interface KeycloakUserEntity {
  id: string;
  employer?: EmployerProfile;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  createdAt?: Date;
  externshipApprovers?: ExternshipApproverProfile[];
}

export interface EmployerProfile {
  id: number;

  /**
   * @deprecated
   */
  email: string;
  /**
   * @deprecated
   */
  status: number;
  /**
   * @deprecated
   */
  userId: string;
  /**
   * @deprecated
   */
  firstName: string;
  /**
   * @deprecated
   */
  lastName: string;
  /**
   * @deprecated
   */
  phone: string;
  company?: CompanyProfile;
  companyToEmployers?: CompanyToEmployerLink[];
  jobTitle: string;
  user?: KeycloakUserEntity
  roles?: UserRole[];
  /**
   * @deprecated
   * use companyToEmployers links instead
   */
  owner?: boolean;
  e_source_group?: ESourceGroupEntity;
  externshipWelcomeModalShown?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export interface ExternshipApproverProfile {
  id: number;
  externship: ExternshipEntity;
  user: KeycloakUserEntity;

  firstName: string;
  lastName: string;
  phone: string;
  jobTitle: string;

  //invite: UserInviteEntity;

  approverRole: ApproverRole;
  canApproveTimesheets: boolean;
  proxyAgreement: AgreementEntity;

  createdAt?: Date;
  updatedAt?: Date;
}

export interface CompanyToEmployerLink {
  id: number;
  active: boolean;
  employerRole: EmployerRole;
  phone: string;
  jobTitle: string;
  company: CompanyProfile;
  employer: EmployerProfile;
  createdAt: Date;
  updatedAt: Date;
}
