import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckboxSingle from '@components/CheckboxSingle';
import { frequencyOptions } from '@api/models/notificationsFrequencyApi.models';
import { NotificationsApi } from '@api/Notifications.api';
import { NotificationsFrequencyApi } from '@api/NotificationsFrequency.api';
import StepSection from '@components/StepSection';
import StepSubSection from '@components/StepSubSection';
import { styled } from '@mui/system';
import ToggleButton from '@components/ToggleButton';
import { useKeycloakContext } from '@common/context/keycloakContext';
import { UserNotificationType } from '@api/models/notificationsApi.models';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import { useToast } from '@components/Toast';

const Styled = {
  ToggleBox: styled(Box)({
    marginBottom: '10px',
    marginLeft: '-8px'
  }),
  UnSubscribeBtn: styled(Button)(({ theme }) => ({
    color: theme.palette.GRAY_3.main,
    marginTop: '15px',
    padding: 0
  })),
  FrequencyCheckBox: styled(Box)({
    marginTop: '15px'
  })
};

const employerNotifications = [
  {
    value: 1001,
    type: UserNotificationType.NEW_CANDIDATE_NOTIFICATION,
    label: 'New Candidate Application Alerts'
  }
];

const adminNotifications = [
  {
    value: 1011,
    type: UserNotificationType.NEW_EMPLOYER_NOTIFICATION,
    label: 'New Employer Application Alerts'
  }
];

interface Notification {
  value: number;
  label: string;
  type: string;
}

interface NotificationType {
  isDefault?: boolean;
  type: string;
  isEnabled: boolean;
}

interface Frequency {
  employerId?: number;
  frequencyId: number;
  userId?: string;
  isEnabled: boolean;
}

const NotificationSettings: React.FC = () => {
  const [isTypeSelected, setIsTypeSelected] = React.useState<boolean>(false);
  const [frequencyIds, setFrequencyIds] = React.useState<number[]>([]);
  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  );
  const { keycloakUser, isEcAdmin, isEmployer, isRecruiter } =
    useKeycloakContext();

  const { openToast } = useToast();

  const [primaryKeycloakRole] = useStore<string>(UseStoreKeys.PRIMARY_KEYCLOAK_ROLE);
  const [employerId] = useStore<number>(UseStoreKeys.EMPLOYER_ID);

  const notificationOptions: Notification[] = React.useMemo(() => {
    if (isEcAdmin) {
      return adminNotifications;
    }

    if (isEmployer || isRecruiter) {
      return employerNotifications;
    }

    return [];
  }, [isEcAdmin, isEmployer, isRecruiter]);

  React.useEffect(() => {
    const notification = notifications.find((n): boolean => n.isEnabled);
    setIsTypeSelected(notification?.isEnabled as boolean);
  }, [notifications.length]);

  // Fetch user notifications
  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await NotificationsApi.getNotificationsByUserId(
          keycloakUser.sub,
          primaryKeycloakRole
        );
        setNotifications(response.data);
      } catch (error) {
        console.error(
          'Error for NotificationApi.getNotificationsByEmployerId()',
          error
        );
      }
    };
    fetchData();
  }, [isTypeSelected]);

  // Fetch user notification frequencies
  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response =
          await NotificationsFrequencyApi.getNotificationsFrequencyByUserId(
            keycloakUser.sub
          );

        setFrequencyIds(
          response.data.map((frequency) => frequency.frequencyId)
        );
      } catch (error) {
        console.error(
          'Error for NotificationApi.getNotificationsFrequencyByEmployerId()',
          error
        );
      }
    };
    fetchData();
  }, []);

  const handleNotificationType = async (type: string): Promise<void> => {
    const notification = notifications.find((n): boolean => n.type === type);
    const notificationTitle = notificationOptions.find(
      (n) => n.type === type
    )?.label;

    const data = {
      userId: keycloakUser?.sub,
      employerId,
      type,
      isEnabled: notification?.isDefault ? false : !notification?.isEnabled
    };

    await NotificationsApi.updateNotification(data);

    setIsTypeSelected(!isTypeSelected);
    openToast(
      !isTypeSelected
        ? `${notificationTitle} is enabled`
        : `${notificationTitle} is disabled`
    );
  };

  const handleFrequencyChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    frequencyId: number
  ): Promise<void> => {
    const frequencyLabel = frequencyOptions.find(
      (frequency) => frequency.id === frequencyId
    )?.label;
    if (frequencyIds.includes(frequencyId)) {
      const frequenciesCopy = [...frequencyIds];
      const index = frequencyIds.indexOf(frequencyId);
      frequenciesCopy.splice(index, 1);
      setFrequencyIds(frequenciesCopy);
      openToast(`${frequencyLabel} Off`);
    } else {
      setFrequencyIds([...frequencyIds, frequencyId]);
      openToast(`${frequencyLabel} On`);
    }

    const data: Frequency = {
      employerId: employerId,
      frequencyId: frequencyId,
      isEnabled: event.target.checked,
      userId: keycloakUser.sub
    };
    await NotificationsFrequencyApi.updateNotificationFrequency(data);
  };

  const resetNotificationsOption = async (): Promise<void> => {
    try {
      for (const notification of notifications) {
        await NotificationsApi.updateNotification({
          employerId: employerId,
          userId: keycloakUser.sub,
          type: notification.type,
          isEnabled: false
        });
      }
      openToast('You are unsubscribed from all notifications');
      setIsTypeSelected(false);
    } catch (error) {
      console.error(
        'Error for NotificationApi.getNotificationsFrequencyByEmployerId()',
        error
      );
    }
  };

  const isFrequencyChecked = (frequencyId: number): boolean => {
    return frequencyIds.includes(frequencyId);
  };

  return (
    <>
      <StepSection title="Manage Notifications">
        <StepSubSection title="Enable Notifications">
          <Grid container>
            <Grid item xs={12} md={9}>
              {notificationOptions &&
                notificationOptions.map((item) => (
                  <Styled.ToggleBox key={item.value}>
                    <ToggleButton
                      isChecked={isTypeSelected ? isTypeSelected : false}
                      data-testid="notification-option"
                      disabled={false}
                      handleVisibility={(): Promise<void> =>
                        handleNotificationType(item.type)
                      }
                      displayText={{
                        checked: item.label,
                        unchecked: item.label
                      }}
                    />
                  </Styled.ToggleBox>
                ))}
            </Grid>
          </Grid>
          <Styled.UnSubscribeBtn
            variant="text"
            onClick={resetNotificationsOption}
            data-testid="unsubscribe-button"
          >
            Unsubscribe From All
          </Styled.UnSubscribeBtn>
        </StepSubSection>
        {isTypeSelected && (
          <StepSubSection title="Email Frequency">
            <Grid container>
              <Grid item sm={12} md={10}>
                {notifications.map((notification) => (
                  <Typography
                    key={notification.type}
                    variant="EC_TYPE_BASE"
                    data-testid="frequency-option-title"
                  >
                    {notification.type ===
                      UserNotificationType.NEW_CANDIDATE_NOTIFICATION &&
                      'New Candidate Application Alerts'}
                    {notification.type ===
                      UserNotificationType.NEW_EMPLOYER_NOTIFICATION &&
                      'New Employer Application Alerts'}
                  </Typography>
                ))}
                <Styled.FrequencyCheckBox>
                  {frequencyOptions &&
                    frequencyOptions.map((frequency) => (
                      <CheckboxSingle
                        key={frequency.id}
                        label={frequency.label}
                        control={
                          <Checkbox
                            checked={isFrequencyChecked(frequency.id)}
                            value={frequency.id}
                          />
                        }
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): Promise<void> =>
                          handleFrequencyChange(e, frequency.id)
                        }
                        name={frequency.name}
                      />
                    ))}
                </Styled.FrequencyCheckBox>
              </Grid>
            </Grid>
          </StepSubSection>
        )}
      </StepSection>
    </>
  );
};

export default NotificationSettings;
