import { Box, Switch, Typography } from '@mui/material';
import BasicModal from '@components/BasicModal';
import BottomMarginContentWrapper from '@components/BottomMarginContentWrapper';
import { ExternshipApi } from '@api/Externships.api';
import React from 'react';
import theme from '@themes/ui/escoffier';
import { useStudentViewContext } from '@common/context/studentViewContext';
import { ExternshipApproverEntity } from '@api/models/externshipApi.models';

interface ApproverActivationProps {
  externshipId: number;
  approver: ExternshipApproverEntity,
  disabled: boolean;
}

const ApproverActivation: React.FC<ApproverActivationProps> = ({
  externshipId,
  approver,
  disabled
}) => {
  const { setExternships } = useStudentViewContext();
  const [activated, setActivated] = React.useState<boolean | undefined>(approver.canApproveTimesheets);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    let externship;
    try {
      externship = await ExternshipApi.toggleApproverActiveStatus({
        externshipId: externshipId,
        supervisorId: approver.id
      });
    } catch (e) {
      console.log('Error while (de)activating an approver. Error:', e);
    } finally {
      setOpenModal(false);
      setActivated(!activated);

      setExternships((prev) => {
        return [...prev].map((e) => {
          if (e.id === externshipId) {
            return {
              ...externship,
              course: e.course
            };
          }
          return { ...e };
        });
      });
    }
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="EC_TYPE_2XS"
          color={theme.palette.primary.main}
          textAlign="end"
          paddingRight={'10px'}
        >
          TIMESHEET <br /> ACCESS
        </Typography>
        <Switch
          disabled={disabled}
          checked={activated}
          onChange={(): void => setOpenModal(true)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>

      <BasicModal
        open={openModal}
        title={`${activated ? 'Deactivate' : 'Activate'} Approver`}
        primaryAction={{
          label: 'Yes',
          action: handleSubmit
        }}
        secondaryAction={{
          label: 'No',
          action: (): void => setOpenModal(false)
        }}
        maxWidth="sm"
      >
        <BottomMarginContentWrapper bottomMargin="24px">
          <Typography variant="EC_TYPE_BASE">
            You are about to {activated && 'de'}activate{' '}
            <strong>{approver.user?.email}</strong> from this externship.
            <br />
            <br />
            {!activated
              ? 'Active users  receive notifications about this student and can sign timecards.'
              : 'Inactive users will not receive notifications about this student and will not be able to sign timecards.'}
          </Typography>
        </BottomMarginContentWrapper>
      </BasicModal>
    </Box>
  );
};

export default ApproverActivation;
