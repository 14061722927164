import * as React from 'react';
import {
  redirectStorageKeys,
  redirectStorageMessages,
  useStateWithLocalStorage
} from '@common/helpers/storage';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AdminDashboardPage from '@pages/EcAdmin/AdminDashboardPage/AdminDashboardPage';
import ApprovalsPage from '@pages/CareerServices/ApprovalsPage/ApprovalsPage';
import Box from '@mui/material/Box';
import CandidateDashboard from '@pages/Candidate/CandidateDashboard/CandidateDashboard';
import CandidateMarketplacePage from '@pages/Employer/CandidateMarketplacePage/CandidateMarketplacePage';
import CandidateProfilePage from '@pages/Candidate/CandidateProfilePage';
import CandidateProfileSetupPage from '@pages/Candidate/CandidateProfileSetupPage/CandidateProfileSetupPage';
import CareerMarketplaceLanding from '@pages/Shared/CareerMarketplace/CareerMarketplaceLanding';
import CareerPathPage from '@pages/Candidate/CareerPathPage/CareerPathPage';
import CareerQuizPage from '@pages/Public/CareerQuizPage/CareerQuizPage';
import CareerQuizResults from '@pages/Public/CareerQuizPage/CareerQuizResults';
import CareerServicesAttestationsPage from '@pages/CareerServices/AttestationsPage/CareerServicesAttestationsPage';
import CareerServicesDashboardPage from '@pages/CareerServices/CareerServicesDashboardPage/CareerServicesDashboardPage';
import ChefInstructorDashboardPage from '@pages/ChefInstructor/ChefInstructorDashboardPage';
import CompanyThemeProviderWrapper from '@common/utilities/CompanyThemeProviderWrapper';
import ContactUsPage from '@pages/Public/ContactUsPage/ContactUsPage';
import ContentManagementPage from '@pages/EcAdmin/ContentManagementPage/ContentManagementPage';
import CreateAccountPage from '@pages/Public/CreateAccountPage/CreateAccountPage';
import CreateJobPostPage from '@pages/Employer/CreateJobPostPage/CreateJobPostPage';
import CsCompaniesPage from '@pages/CareerServices/CsCompaniesPage/CsCompaniesPage';
import CssBaseline from '@mui/material/CssBaseline';
import DeprecatedChefInstructorDashboardPage from '@pages/ChefInstructor/DEPRECATED_EC1_5806_ChefInstructorDashboardPage';
import EcRoute from '@common/utilities/EcRoute';
import EmployerDashboard from '@pages/Employer/EmployerDashboard/EmployerDashboard';
import EmployerProfilePage from '@pages/Employer/ProfilePage/EmployerProfilePage';
import ErrorPage from '@pages/Errors/ErrorPage';
import EsourceSearchPage from '@pages/Partner/EsourceSearchPage/EsourceSearchPage';
import ExpiredInvitePage from '@pages/Errors/ExpiredInvitePage';
import ExternalJobRedirect from '@common/utilities/ExternalJobRedirect';
import ExternshipAttestationPage from '@pages/Candidate/ExternshipAttestationPage/ExternshipAttestationPage';
import ExternshipInviteErrorPage from '@pages/Errors/ExternshipInviteErrorPage';
import ExternshipManagerVerificationPage from '@pages/Employer/ExternshipManagerVerificationPage/ExternshipManagerVerificationPage';
import ExternshipProposalPage from '@pages/Candidate/ExternshipProposalPage/ExternshipProposalPage';
import ExternshipTrackerPage from '@pages/CareerServices/ExternshipTrackerPage/ExternshipTrackerPage';
import GlrcCartComponentPage from '@pages/DevPages/Components/GlrcCartComponentPage';
import GlrcCatalogPage from '@pages/Partner/GlrcCatalogPage/GlrcCatalogPage';
import GlrcContentPage from '@pages/Partner/GlrcContentPage/GlrcContentPage';
import GlrcProductCardComponentPage from '@pages/DevPages/Components/GlrcProductCardComponentPage';
import GlrcTermsAndConditionsPage from '@pages/Public/GlrcTermsAndCondiotionsPage/GlrcTermsAndConditionsPage';
import GoldCrownPartnershipTermsAndConditionsPage from '@pages/Public/GoldCrownPartnershipTermsAndConditionsPage/GoldCrownPartnershipTermsAndConditionsPage';
import GoogleAnalyticsWrapper from '@common/utilities/GoogleAnalyticsWrapper';
import HeaderFooter from '@components/Layout/HeaderFooter';
import { HelmetProvider } from 'react-helmet-async';
import InvalidInviteCodePage from '@pages/Errors/InvalidInviteCodePage';
import JobApplicantsPage from '@pages/Employer/JobCandidates/JobApplicantsPage';
import JobListingLandingPage from '@pages/Employer/PreviewPage/JobListingLandingPage';
import keycloak from './keycloak';
import KeycloakProviderWrapper from '@common/utilities/KeycloakProviderWrapper';
import KeycloakReroute from '@common/utilities/KeycloakReroute';
import LearningCenterPage from '@pages/Partner/LearningCenterPage/LearningCenterPage';
import LegalPage from '@pages/Public/LegalPage/LegalPage';
import ManageCompaniesPage from '@pages/EcAdmin/ManageCompaniesPage/ManageCompaniesPage';
import NotFoundPage from '@pages/Errors/NotFoundPage';
import ParentDashboardPage from '@pages/ParentCompany/ParentDashboardPage';
import PartnerEmployeeCareerJourneyPage from '@pages/Partner/PartnerEmployeeCareerJourney/PartnerEmployeeCareerJourneyPage';
import PartnerEmployeeProfileSetupPage from '@pages/Partner/PartnerEmployeeProfileSetupPage/PartnerEmployeeProfileSetupPage';
import PartnerSignInPage from '@pages/Public/PartnerSignInPage/PartnerSignInPage';
import PendingUserDashboard from '@pages/Employer/EmployerDashboard/PendingUserDashboard';
import { Permission } from './permissions/permission.enum';
import PrivacyPolicyPage from '@pages/Public/PrivacyPolicyPage/PrivacyPolicyPage';
import PrivateRoute from '@common/utilities/PrivateRoute';
import ProfileSetupPage from '@pages/Employer/ProfileSetupPage/ProfileSetupPage';
import RedirectDashboardRoute from '@common/utilities/RedirectDashboardRoute';
import RedirectLogoutRoute from '@common/utilities/RedirectLogoutRoute';
import RedirectPage from '@components/RedirectPage';
import { roles } from '@api/models/userApi.models';
import SettingsPage from '@pages/Shared/SettingsPage/SettingsPage';
import StudentPage from '@pages/CareerServices/StudentPage/StudentPage';
import { styled } from '@mui/system';
import StyleGuidePage from '@pages/DevPages/StyleGuidePage';
import TermsOfUsePage from '@pages/Public/TermsOfUsePage/TermsAndConditionsPage';
import theme from '@themes/ui/escoffier';
import { ThemeProvider } from '@mui/material/styles';
import TimedOutPage from '@pages/Errors/TimedOutPage';
import TimesheetPage from '@pages/Shared/TimesheetPage/TimesheetPage';
import TimesheetPageMultiSite from '@pages/Shared/TimesheetPage/TimesheetPageMultiSite';
import { ToastContainer } from 'react-toastify';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useStore } from 'react-context-hook';
import { UseStoreKeys } from '@common/utilities/UseStoreKeys';
import WorkAndLearnCandidatePage from '@pages/Public/WorkAndLearnPage/WorkAndLearnCandidatePage/WorkAndLearnCandidatePage';
import './App.css';
import 'remixicon/fonts/remixicon.css';
import 'react-toastify/dist/ReactToastify.css';
import ExternshipTimesheetApproverDashboard from '@pages/ExternshipTimesheetApprover/ExternshipTimesheetApproverDashboard';

const Styled = {
  Wrapper: styled(Box)({
    minHeight: '100vh',
    paddingBottom: '75px',
    position: 'relative'
  })
};

const App: React.FC = () => {
  const loaded = React.useRef(false);

  const [isEnabledCompany] = useStore<boolean>(UseStoreKeys.COMPANY_ENABLED);
  const [tokenExpired] = useStore<boolean>(UseStoreKeys.TOKEN_EXPIRED);
  const featureFlags = useFeatureFlags();
  const isDev = process.env.REACT_APP_FEATURE_FLAG_ENV === 'dev';
  const isProd = process.env.REACT_APP_FEATURE_FLAG_ENV === 'prod';

  const { setStoredValue } = useStateWithLocalStorage(
    redirectStorageKeys.USER_TIMEOUT
  );

  // Handle token time out
  React.useEffect(() => {
    const redirect = window.location.href;

    if (tokenExpired) {
      setStoredValue(
        JSON.stringify({
          redirect,
          message: redirectStorageMessages.NO_USER
        })
      );

      keycloak.logout({ redirectUri: `${window.location.origin}/timeout` });
    }
  }, [tokenExpired]);

  const loadScript = (
    src: string,
    position: HTMLElement | null,
    id: string
  ): void => {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const RedirectPageWithText = (): JSX.Element | null => (
    <RedirectPage
      text={!isProd ? 'Will redirect in production environment' : undefined}
    />
  );

  return (
    <KeycloakProviderWrapper>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <CssBaseline />
          <ToastContainer newestOnTop={false} rtl={false} />
          <Styled.Wrapper>
            <Router>
              <GoogleAnalyticsWrapper>
                <HeaderFooter>
                  <Switch>
                    <EcRoute
                      exact
                      path="/keycloak"
                      component={KeycloakReroute}
                    />
                    <EcRoute
                      exact
                      path="/external-job"
                      component={ExternalJobRedirect}
                    />
                    <PrivateRoute
                      on={!!featureFlags.REPORTING_DASH}
                      requiredPermissions={[Permission.ADMIN_DASHBOARD_VIEW]}
                      path="/admin/dashboard"
                      component={AdminDashboardPage}
                      title="Admin Dashboard"
                    />
                    <PrivateRoute
                      on={!!featureFlags.REPORTING_DASH}
                      requiredPermissions={[
                        Permission.ADMIN_COMPANIES_VIEW,
                        Permission.ADMIN_COMPANIES_EDIT
                      ]}
                      path="/admin/manage-companies"
                      component={ManageCompaniesPage}
                      title="Manage Companies"
                    />
                    <PrivateRoute
                      on={!!featureFlags.CONTENT_MANAGEMENT}
                      allowedRoles={[roles.ESCOFFIER_ADMIN]}
                      path="/admin/content-management"
                      component={ContentManagementPage}
                      title="Content Management"
                    />
                    <EcRoute
                      exact
                      path="/"
                      redirect={
                        isProd
                          ? 'https://escoffierglobal.com/econnect'
                          : undefined
                      }
                      component={RedirectPageWithText}
                      title="Home"
                    />
                    <RedirectLogoutRoute path="/logout" />
                    <EcRoute
                      path="/create-account"
                      component={CreateAccountPage}
                      title="Create Account"
                      description="Create an account with EConnect today."
                    />
                    <EcRoute
                      path="/candidate/offerings"
                      redirect={
                        isProd
                          ? 'https://escoffierglobal.com/econnect'
                          : undefined
                      }
                      component={RedirectPageWithText}
                      title="Candidate Offering"
                    />
                    <PrivateRoute
                      on={!!featureFlags.CANDIDATE_MARKETPLACE}
                      requiredPermissions={[Permission.TALENT_SEARCH_VIEW]}
                      path="/talent-search"
                      component={CandidateMarketplacePage}
                      title="Talent Search"
                    />
                    <PrivateRoute
                      on={!!featureFlags.CANDIDATE_MARKETPLACE}
                      requiredPermissions={[Permission.TALENT_SEARCH_VIEW]}
                      path="/employer/:companyId/talent-search"
                      component={CandidateMarketplacePage}
                      title="Talent Search"
                    />
                    {/* Redirect for users who have bookmarked old route for marketplace */}
                    <EcRoute
                      path="/career-marketplace"
                      internalRedirect="/econnect"
                      component={CareerMarketplaceLanding}
                      title="EConnect"
                      description="Looking for a new opportunity? EConnect is the perfect place to start applying to jobs you think would be a good match."
                    />
                    <EcRoute
                      path="/econnect"
                      component={CareerMarketplaceLanding}
                      title="EConnect"
                      description="Looking for a new opportunity? EConnect is the perfect place to start applying to jobs you think would be a good match."
                    />
                    <PrivateRoute
                      allowAllAuthenticated={true}
                      path="/settings"
                      title="Settings"
                      component={SettingsPage}
                    />
                    <PrivateRoute
                      allowedRoles={[roles.ESCOFFIER_ADMIN]}
                      path="/employer/:companyId/settings"
                      title="Settings"
                      component={SettingsPage}
                    />
                    {/* CAREER SERVICES ROUTES START */}
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_DASHBOARD_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/career-services/dashboard"
                      component={CareerServicesDashboardPage}
                      title="Career Services Dashboard"
                    />
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_DASHBOARD_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/externship-tracker"
                      component={ExternshipTrackerPage}
                      title="Externship Tracker"
                    />
                    <PrivateRoute
                      exact
                      on={!!featureFlags.EXTERNSHIPS}
                      requiredPermissions={[Permission.EXTERNSHIP_VERIFICATION]}
                      path="/externship/:externshipId/manager-verification"
                      title="Manager Verification"
                      component={ExternshipManagerVerificationPage}
                    />
                    {/* SECRET ROUTE FOR CS USERS TO SEE ALL EA'S AWAITING APPROVALS */}
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_APPROVALS_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/career-services/approvals"
                      component={ApprovalsPage}
                      title="Career Services Approvals"
                    />
                    <PrivateRoute
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_DASHBOARD_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/career-services/candidate/:candidateId"
                      title="Site Information"
                      component={StudentPage}
                    />
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_APPROVALS_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/externship-attestations"
                      component={CareerServicesAttestationsPage}
                      title="Extern Attestations"
                    />
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CAREER_SERVICE_DASHBOARD_VIEW
                      ]}
                      path="/career-services/companies"
                      component={CsCompaniesPage}
                      title="Companies"
                    />
                    {/* CAREER SERVICES ROUTES END */}
                    {/* CHEF INSTRUCTOR ROUTES START */}
                    {/* TODO: Delete route when new dash is fully adopted */}
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CHEF_INSTRUCTOR_DASHBOARD_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/chef-instructor/deprecated-dashboard"
                      component={DeprecatedChefInstructorDashboardPage}
                      title="Chef Instructor Dashboard"
                    />
                    <PrivateRoute
                      exact
                      requiredPermissions={[
                        Permission.CHEF_INSTRUCTOR_DASHBOARD_VIEW
                      ]}
                      on={!!featureFlags.EXTERNSHIPS}
                      path="/chef-instructor/dashboard"
                      component={ChefInstructorDashboardPage}
                      title="Chef Instructor Dashboard"
                    />
                    {/* CHEF INSTRUCTOR ROUTES END */}
                    <PrivateRoute
                      allowedRoles={[roles.CANDIDATE]}
                      path="/candidate/dashboard"
                      title="Candidate Dashboard"
                      component={CandidateDashboard}
                    />
                    <RedirectDashboardRoute path="/user/dashboard" />
                    <PrivateRoute
                      allowedRoles={[roles.CANDIDATE]}
                      path="/candidate/profile-setup"
                      title="Candidate Profile Setup"
                      component={CandidateProfileSetupPage}
                    />
                    {/* EXTERNSHIP ROUTES START */}
                    <PrivateRoute
                      exact
                      on={!!featureFlags.EXTERNSHIPS}
                      allowedRoles={[roles.CANDIDATE]}
                      path="/externship/proposal"
                      title="Externship Proposal"
                      component={ExternshipProposalPage}
                    />
                    <PrivateRoute
                      exact
                      on={!!featureFlags.EXTERNSHIPS}
                      allowedRoles={[roles.CANDIDATE]}
                      path="/externship/:externshipId/proposal"
                      title="Externship Proposal"
                      component={ExternshipProposalPage}
                    />
                    {/* TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove existing proposal routes above and use the ones below */}
                    <PrivateRoute
                      exact
                      on={
                        !!featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
                        !!featureFlags.EXTERNSHIPS
                      }
                      allowedRoles={[roles.CANDIDATE]}
                      path="/enrollment/:enrollmentId/course/:courseId/externship/proposal"
                      title="Externship Proposal"
                      component={ExternshipProposalPage}
                    />
                    <PrivateRoute
                      exact
                      on={
                        !!featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE &&
                        !!featureFlags.EXTERNSHIPS
                      }
                      allowedRoles={[roles.CANDIDATE]}
                      path="/enrollment/:enrollmentId/course/:courseId/externship/:externshipId/proposal"
                      title="Externship Proposal"
                      component={ExternshipProposalPage}
                    />
                    <PrivateRoute
                      exact
                      on={!!featureFlags.EXTERNSHIPS}
                      requiredPermissions={[Permission.ATTESTATION_SUBMIT]}
                      path="/externship/:externshipId/attestation"
                      title="Externship Attestation"
                      component={ExternshipAttestationPage}
                    />

                    {/* EXTERNSHIP ROUTES END */}
                    {/* PENDING USER ROUTES START */}
                    <PrivateRoute
                      allowedRoles={[roles.PENDING_USER]}
                      path="/pending-user/dashboard"
                      component={PendingUserDashboard}
                      title="Employer Dashboard"
                    />
                    {/* PENDING USER ROUTES END */}
                    <PrivateRoute
                      allowedRoles={[roles.EMPLOYER]}
                      path="/employer/profile-setup"
                      title="Employer Profile Setup"
                      component={ProfileSetupPage}
                    />
                    <PrivateRoute
                      allowedRoles={[roles.PARTNER]}
                      path="/partner-employee/profile-setup"
                      title="Partner Employee Profile Setup"
                      component={PartnerEmployeeProfileSetupPage}
                    />
                    <PrivateRoute
                      allowedRoles={[roles.ESCOFFIER_ADMIN, roles.EMPLOYER]}
                      path="/employer/:companyId/profile-setup"
                      title="Employer Profile Setup"
                      component={ProfileSetupPage}
                    />
                    <PrivateRoute
                      allowedRoles={[roles.EMPLOYER, roles.RECRUITER]}
                      path="/employer/dashboard"
                      component={EmployerDashboard}
                      title="Employer Dashboard"
                    />
                    <PrivateRoute
                      allowedRoles={[roles.EXTERNSHIP_TIMESHEET_APPROVER]}
                      path="/approver/dashboard"
                      component={ExternshipTimesheetApproverDashboard}
                      title="Externship Approver Dashboard"
                    />
                    <PrivateRoute
                      allowedRoles={[
                        roles.EMPLOYER,
                        roles.RECRUITER,
                        roles.ESCOFFIER_ADMIN
                      ]}
                      path="/employer/:companyId/dashboard"
                      component={EmployerDashboard}
                      title="Employer Dashboard"
                    />
                    <PrivateRoute
                      on={isEnabledCompany}
                      allowedRoles={[roles.EMPLOYER, roles.RECRUITER]}
                      path="/employer/create-job-post"
                      component={CreateJobPostPage}
                      title="Create Job Post"
                    />
                    <PrivateRoute
                      allowedRoles={[roles.ESCOFFIER_ADMIN, roles.EMPLOYER]}
                      path="/employer/:companyId/create-job-post"
                      component={CreateJobPostPage}
                      title="Create Job Post"
                    />
                    <EcRoute
                      exact
                      path="/job/:jobId"
                      component={JobListingLandingPage}
                      title="Job Listing"
                    />
                    <PrivateRoute
                      exact
                      allowedRoles={[roles.EMPLOYER, roles.RECRUITER]}
                      path="/job/:jobId/edit"
                      component={CreateJobPostPage}
                      title="Edit Job Post"
                    />
                    <PrivateRoute
                      exact
                      allowedRoles={[roles.ESCOFFIER_ADMIN, roles.EMPLOYER]}
                      path="/employer/:companyId/job/:jobId/edit"
                      component={CreateJobPostPage}
                      title="Edit Job Post"
                    />
                    <PrivateRoute
                      allowedRoles={[
                        roles.EMPLOYER,
                        roles.RECRUITER,
                        roles.ESCOFFIER_ADMIN
                      ]}
                      path="/job/:jobId/applicants"
                      component={JobApplicantsPage}
                      title="Job Applicants"
                    />
                    <PrivateRoute
                      allowedRoles={[roles.ESCOFFIER_ADMIN, roles.EMPLOYER]}
                      path="/employer/:companyId/job/:jobId/applicants"
                      component={JobApplicantsPage}
                      title="Job Applicants"
                    />
                    <PrivateRoute
                      exact
                      allowedRoles={[
                        roles.CANDIDATE,
                        roles.EMPLOYER,
                        roles.RECRUITER,
                        roles.ESCOFFIER_ADMIN
                      ]}
                      path="/employer/:id/profile"
                      component={EmployerProfilePage}
                      title="Employer Profile"
                    />
                    <PrivateRoute
                      allowedRoles={[
                        roles.EMPLOYER,
                        roles.RECRUITER,
                        roles.CANDIDATE,
                        roles.ESCOFFIER_ADMIN,
                        roles.EC_CAREER_SERVICE
                      ]}
                      path="/candidate/:id/profile"
                      component={CandidateProfilePage}
                      title="Candidate Profile"
                    />
                    <PrivateRoute
                      allowedRoles={[
                        roles.EMPLOYER,
                        roles.ESCOFFIER_ADMIN,
                        roles.PARTNER
                      ]}
                      path="/partner-employee/career-journey"
                      component={PartnerEmployeeCareerJourneyPage}
                      title="Career Journey"
                    />
                    <EcRoute
                      exact
                      path="/employer/offerings"
                      redirect={
                        isProd ? 'https://escoffierglobal.com' : undefined
                      }
                      component={RedirectPageWithText}
                      title="Employer Offerings"
                    />
                    <PrivateRoute
                      exact
                      on={!!featureFlags.WORK_LEARN_CANDIDATE_PAGE}
                      allowedRoles={[roles.CANDIDATE]}
                      path="/candidate/work-and-learn"
                      component={WorkAndLearnCandidatePage}
                      title="Work and Learn"
                    />
                    <EcRoute
                      path="/legal"
                      component={LegalPage}
                      title="Legal"
                    />
                    <EcRoute
                      path="/privacy-policy"
                      component={PrivacyPolicyPage}
                      title="Privacy Policy"
                      description="Our Privacy Policy was developed as an extension of our commitment to combine the highest quality products and services with the highest level of integrity in dealing with our users. The Policy is designed to assist you in understanding how we collect, use, disclose and safeguard personal and company information, and to assist you in making informed decisions, as you seek job opportunities or needed employees on this website, related phone apps, and related social media accounts (the “Website”), or when you otherwise engage with us or others on the Website. This statement will be continuously assessed against new technologies, business practices, and our customers’ needs."
                    />
                    <EcRoute
                      path="/terms-of-use"
                      component={TermsOfUsePage}
                      title="Terms and Conditions"
                      description='These Terms of Use ("Terms") apply to job seekers, employers or other users ("you") of this website, any related phone apps, and any related social media accounts (the “Website”). These Terms include our Privacy Policy (https://go.escoffierglobal.com/privacy-policy)'
                    />
                    <EcRoute
                      path="/glrc-terms-and-conditions"
                      component={GlrcTermsAndConditionsPage}
                      title="GLRC T and Conditions"
                    />
                    <EcRoute
                      path="/gold-crown-terms-and-conditions"
                      component={GoldCrownPartnershipTermsAndConditionsPage}
                      title="Gold Crown Partnership Terms and Conditions"
                    />
                    <EcRoute
                      path="/contact-us"
                      component={ContactUsPage}
                      title="Contact Us"
                      description="Escoffier wants to hear from you - contact us."
                    />
                    <PrivateRoute
                      on={!!featureFlags.PARENT_DASH}
                      allowedRoles={[roles.EMPLOYER]}
                      path="/parent/dashboard"
                      component={ParentDashboardPage}
                      title="Parent Dashboard"
                    />
                    <PrivateRoute
                      on={!!featureFlags.PARENT_DASH}
                      allowedRoles={[roles.ESCOFFIER_ADMIN]}
                      path="/parent/:companyId/dashboard"
                      component={ParentDashboardPage}
                      title="Parent Dashboard"
                    />
                    <EcRoute
                      exact
                      on={!!featureFlags.CAREER_QUIZ}
                      path="/quiz/:id"
                      component={CareerQuizPage}
                      title="Career Quiz Intro"
                    />
                    <EcRoute
                      exact
                      on={!!featureFlags.CAREER_QUIZ}
                      path="/quiz/:id/results"
                      component={CareerQuizResults}
                      title="Career Quiz Results"
                    />
                    <PrivateRoute
                      on={!!featureFlags.CAREER_PATH}
                      allowedRoles={[roles.CANDIDATE]}
                      path="/career-journey"
                      component={CareerPathPage}
                      title="Career Path"
                    />
                    <EcRoute
                      exact
                      on={!!featureFlags.PARTNER_EXPERIENCE}
                      path="/partnerships"
                      title="Partnerships"
                      component={PartnerSignInPage}
                    />
                    {/* GLRC ROUTES START */}
                    <PrivateRoute
                      exact
                      on={!!featureFlags.PARTNER_EXPERIENCE}
                      requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                      title="Learning Center"
                      path={[
                        '/learning-center',
                        '/employer/:companyId/learning-center',
                        '/learning-center/:topicPath',
                        '/employer/:companyId/learning-center/:topicPath',
                        '/learning-center-search',
                        '/employer/:companyId/learning-center-search'
                      ]}
                      component={(): JSX.Element => (
                        <CompanyThemeProviderWrapper>
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/learning-center"
                            title="Learning Center"
                            component={LearningCenterPage}
                          />
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/employer/:companyId/learning-center"
                            title="Learning Center"
                            component={LearningCenterPage}
                          />
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/learning-center/:topicPath"
                            title="Learning Center"
                            component={GlrcContentPage}
                          />
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/employer/:companyId/learning-center/:topicPath"
                            title="Learning Center"
                            component={GlrcContentPage}
                          />
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/learning-center-search"
                            title="Search Page"
                            component={EsourceSearchPage}
                          />
                          <PrivateRoute
                            exact
                            on={!!featureFlags.PARTNER_EXPERIENCE}
                            requiredPermissions={[Permission.PARTNER_GLRC_VIEW]}
                            path="/employer/:companyId/learning-center-search"
                            title="Search Page"
                            component={EsourceSearchPage}
                          />
                        </CompanyThemeProviderWrapper>
                      )}
                    />
                    {/* GLRC ROUTES END */}
                    {/* TODO: MULTIPLE_EXTERNSHIPS_PER_COURSE - remove this route and use the one below when flag is updated */}
                    <PrivateRoute
                      exact
                      on={!!featureFlags.EXTERNSHIPS}
                      requiredPermissions={[
                        Permission.EXTERNSHIP_TIMESHEET_READ
                      ]}
                      path="/externship/:externshipId/timesheets"
                      title="Timesheets"
                      component={TimesheetPage}
                    />
                    <PrivateRoute
                      exact
                      on={
                        !!featureFlags.EXTERNSHIPS &&
                        !!featureFlags.MULTIPLE_EXTERNSHIPS_PER_COURSE
                      }
                      requiredPermissions={[
                        Permission.EXTERNSHIP_TIMESHEET_READ
                      ]}
                      path="/candidate/:candidateId/course/:courseId/timesheets"
                      title="Timesheets"
                      component={TimesheetPageMultiSite}
                    />
                    {/* Start of Dev Only Routes */}
                    <EcRoute
                      on={isDev}
                      path="/dev/style-guide"
                      component={StyleGuidePage}
                      title="Style Guide"
                    />
                    <EcRoute
                      exact
                      on={isDev}
                      path="/dev/components/glrc-product-card"
                      component={GlrcProductCardComponentPage}
                      title="GLRC Product Card"
                    />
                    <EcRoute
                      exact
                      on={isDev}
                      path="/dev/components/glrc-catalog-page"
                      component={GlrcCatalogPage}
                      title="GLRC Catalog Page"
                    />
                    <EcRoute
                      exact
                      on={isDev}
                      path="/dev/components/glrc-cart"
                      component={GlrcCartComponentPage}
                      title="GLRC Cart"
                    />
                    {/* End of Dev Only Routes */}
                    <EcRoute
                      path="/not-found"
                      title="Page Not Found"
                      component={NotFoundPage}
                    />
                    <EcRoute
                      path="/error"
                      title="Error"
                      component={ErrorPage}
                    />
                    <EcRoute
                      path="/timeout"
                      title="Timed Out"
                      component={TimedOutPage}
                    />
                    <EcRoute
                      path="/expired-invite"
                      title="Expired Invite"
                      component={ExpiredInvitePage}
                    />
                    <EcRoute
                      path="/externship-invite-error"
                      title="Expired Invite"
                      component={ExternshipInviteErrorPage}
                    />
                    <EcRoute
                      path="/invalid-invite"
                      title="Invalid Invite"
                      component={InvalidInviteCodePage}
                    />
                    <EcRoute
                      path="*"
                      title="Page Not Found"
                      component={NotFoundPage}
                    />
                  </Switch>
                </HeaderFooter>
              </GoogleAnalyticsWrapper>
            </Router>
          </Styled.Wrapper>
        </HelmetProvider>
      </ThemeProvider>
    </KeycloakProviderWrapper>
  );
};

export default App;
